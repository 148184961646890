document.addEventListener('DOMContentLoaded', function () {
	const frames = document.querySelectorAll('.wp-block-proactive-iframe');

	/* IFRAME RESIZE */
	window.addEventListener('message', function (event) {
		resizeIframe(frames, event);
	});

	// On resize
	window.addEventListener('resize', function (event) {
		resizeIframe(frames, event);
	});
});

function resizeIframe(frames, event) {
	frames.forEach((frame) => {
		const iframe = frame.querySelector('iframe');

		if (iframe.contentWindow === event.source) {
			// if has scrollbar
			if (
				iframe.scrollHeight < event.data ||
				iframe.scrollHeight > event.data
			) {
				iframe.style.height = `${event.data}px`;
				setTimeout(() => {
					frame.classList.add('loaded');
				}, 600);
			}
		}
	});
}
